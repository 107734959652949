<template>
  <div class="col">
    <div
      class="row margintop-20"
      v-if="searchBannerData && searchBannerData.url"
    >
      <b-img
        :src="searchBannerData.url.fileUrl"
        fluid
        class="w-100 h-100"
      ></b-img>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Search-Banner",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      searchBannerData: ({ advertisement }) => advertisement.searchBanner,
    }),
  },
  methods: {
    ...mapActions({
      fetchSearchBannerData: "advertisement/searchBannerData",
    }),
  },

  mounted() {
    this.fetchSearchBannerData();
  },
};
</script>
